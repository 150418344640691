<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onDialogInput"
  >
    <template #activator="{ on }">
      <template v-if="hasButtonSlot">
        <slot
          name="activator-button"
          :on="on"
        />
      </template>
      <template v-else>
        <v-btn
          rounded
          depressed
          color="info"
          class="d-block mx-auto "
          data-cy="change-plan-lets-talk"
          v-on="on"
        >
          {{ $t(`${buttonName}`) }}
        </v-btn>
      </template>
    </template>
    <v-card>
      <v-card-title data-cy="service-order-modal">
        <span
          v-t="submitted ? 'support.thankYouForYourRequest' : 'tips.productDetails.contactAccountManager'"
          class="headline"
        />
        <v-spacer />
        <v-icon
          class="mr-n1"
          data-cy="service-order-modal-close"
          @click="closeModal()"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="request-quotation-modal-content">
          <material-request-quotation-form
            v-if="dialog && !submitted"
            :default-subject="defaultSubject"
          />
          <core-element-created-message v-else />
        </div>
      </v-card-text>
      <v-divider />
      <v-card-text v-if="!submitted">
        <p
          class="mt-5"
          style="text-align: center"
          v-text="
            $t(
              'tips.productDetails.orYouCanReachYourAccountManagerDirectlyOnTheseContacts'
            )
          "
        />
        <div class="d-table ma-auto">
          <core-element-manager-contacts />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { CLOSE_ICON } from '@/constants/app'
import { SUBJECT_PRODUCT_SW } from '@/constants/quotationRequestSubjects'

export default {
  props: {
    hasButtonSlot: {
      type: Boolean,
      default: false
    },
    buttonName: {
      type: String,
      default: 'tips.productDetails.requestQuotation'
    },
    defaultSubject: {
      type: String,
      default: SUBJECT_PRODUCT_SW
    }
  },
  data () {
    return {
      dialog: false,
      submitted: false,
      closeIcon: CLOSE_ICON
    }
  },
  computed: {
    ...mapGetters('client', { billingDetailsGetter: 'billingDetails' }),
    ...mapState('support', ['loading', 'showApiErrors']),
    billingDetails () {
      return this.billingDetailsGetter(this.$route.params.clientId)
    }
  },
  watch: {
    loading (val, oldVal) {
      if (!val && val !== oldVal && !this.showApiErrors) {
        this.submitted = true
      }
    }
  },
  methods: {
    ...mapMutations('support', ['setErrors']),
    closeModal () {
      this.dialog = false
      this.onDialogInput()
    },
    onDialogInput () {
      if (this.dialog) {
        this.setErrors({})
        this.submitted = false
      }
    }
  }
}
</script>

<style scoped>
.request-quotation-modal-content {
  max-width: 700px;
  margin: auto;
}
</style>
